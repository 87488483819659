@import "vars"

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px)
  /**/


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px)
  h2
    font-size: 24px
  .grid
    grid-column-gap: 20px
  .main-header
    .grid
      grid-row-gap: 25px
    .main-logo
      grid-column: 2/14
      justify-self: center
    .nav-menu
      grid-column: 1/9
    .auth
      grid-column: 10/15
      justify-content: space-around
      .button-register
        &:before
          content: none
  .section-slider
    &-left
      grid-column: 1/13
      margin-bottom: 50px
      text-align: center
    &-right
      grid-column: 1/13
  .section-tool
    padding-top: 25px
    padding-bottom: 40px
    h2
      text-align: center
    .grid
      grid-template-rows: initial
    .tool-title
      grid-column: 1/15
      grid-row: initial
      justify-content: center
    .tool-left
      grid-column: 1/15
      grid-row: initial
    .tool-right
      grid-column: 1/15
      grid-row: initial
  .section-how
    padding-top: 25px
    padding-bottom: 40px
    .how-text
      grid-column: 1/9
  .section-features
    padding-bottom: 60px
    .widgets
      margin-bottom: 70px
  .section-price
    padding-top: 25px
    .item-price
      padding: 20px
  .section-form
    .form
      top: -100px
      min-width: 550px
    .form-body
      padding: 5px 40px 30px
  .main-footer
    padding-bottom: 40px
    .footer-left
      grid-column: 1/7
    .footer-right
      grid-column: 11/15



/* Small Devices, Tablets */
@media only screen and (max-width : 768px)
  body
    font-size: 14px
    transition: margin-left .5s
  .mask
    position: fixed
    top: 0px
    right: 0px
    bottom: 0px
    left: 0px
    z-index: 100
    background-color: rgb(0, 0, 0)
    opacity: 0.5
    display: none
    &::before
      content: ''
      background: inherit
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      box-shadow: inset 0 0 0 3000px rgba(255,255,255,0.3)
      filter: blur(10px)
  h2
    text-align: center
    margin-bottom: 15px
  .grid
    grid-template-columns: repeat(12, 1fr)
    grid-column-gap: 10px
  .grid-12
    grid-column-gap: 10px
  .main-header
    padding-top: 25px
    padding-bottom: 30px
    .grid
      grid-row-gap: 10px
    .main-logo
      grid-column: 2/12
    a
      font-size: 16px
    #menu-button
      display: block
      position: relative
      left: -18px
      font-size: 40px
      cursor: pointer
      font-weight: bold
      line-height: 1
      color: $terra-cotta
    .nav-menu
      display: block
      height: 100%
      width: 0
      position: fixed
      z-index: 200
      top: 0
      left: 0
      background-color: white
      overflow-x: hidden
      transition: 0.5s
      padding-top: 60px
      a
        padding: 8px 8px 8px 32px
        text-decoration: none
        font-size: 18px
        color: black
        display: block
        transition: 0.3s
        &:hover
          color: #9b9b9b
      #close-btn
        display: block
        position: absolute
        top: 0
        right: 25px
        font-size: 36px
        margin-left: 50px
        &:focus
          outline: none
      .dropdown
        display: block
        position: relative
        .dropdown-content
          visibility: visible
          opacity: 1
          position: relative
          top: 0
          left: 0
          width: auto
          border-radius: 0
          a
            font-size: 15px

    .auth
      grid-column: 1/13
      justify-self: center
  .section-tool
    padding-top: 15px
    padding-bottom: 25px
    .tool-title
      grid-column: 1/13
    .tool-left
      &>p
        font-size: 17px
      grid-column: 2/12
      .flex
        flex-wrap: wrap
        p
          width: 100%
    .tool-right
      grid-column: 1/13
  .section-how
    padding-top: 15px
    padding-bottom: 25px
    .how-text
      grid-column: 2/12
      grid-row: initial
      margin-bottom: 10px
      p
        font-size: 16px
    .scheme
      grid-row: initial
      .desktop
        display: none
      .mobile
        display: block
        max-height: 850px
  .section-features
    padding-bottom: 40px
    .grid
      grid-row-gap: 20px
    .widgets
      margin-bottom: 45px
      .widget-1
        margin: 0 -15px
        grid-column: 1/13
        grid-row: initial
      .widget-text
        grid-column: 2/12
        grid-row: initial
      .widget-2
        grid-column: 1/5
      .widget-3
        grid-column: 5/9
      .widget-4
        grid-column: 9/13
    .payments
      margin-bottom: 45px
      .payment-text-1
        grid-column: 2/12
        grid-row: initial
      .payment-1
        margin: 0 -15px
        grid-column: 1/13
        grid-row: initial
      .payment-text-2
        grid-column: 2/12
        grid-row: initial
      .payment-2
        margin: 0 -15px
        grid-column: 1/13
        grid-row: initial
    .employees
      .employee-text-1
        order: 1
        grid-column: 2/12
        grid-row: initial
      .employee-1
        margin: 0 -15px
        order: 4
        grid-column: 1/13
        grid-row: initial
      .employee-text-2
        order: 3
        grid-column: 2/12
        grid-row: initial
      .employee-2
        order: 2
        grid-column: 1/13
        grid-row: initial
  .section-price
    padding-top: 5px
    .item-price-1
      grid-column: 2/12
    .item-price-2, .item-price-3
      display: none
    .price-text
      display: none
  .section-form
    margin-top: 0
    .grid
      grid-template-rows: initial
    &.home
      margin-top: -35px
    .form
      grid-column: 1/13
      grid-row: initial
      position: inherit
      top: 0
      left: 0
      margin-bottom: 50px
      min-width: inherit
      -webkit-transform: none
      -moz-transform: none
      -ms-transform: none
      -o-transform: none
      transform: none
    .pic-lamp
      grid-column: 1/5
      grid-row: initial
    .pic-comp
      grid-column: 8/13
      grid-row: initial
  .main-footer
    padding-top: 30px
    h2
      color: black
    .footer-right
      order: 1
      grid-column: 2/12
      margin-bottom: 45px
    .footer-left
      order: 2
      grid-column: 2/12
      text-align: center
      .footer-logo
        display: none
      .terms
        a
          display: inline-block
          font-size: 15px
          margin: 0 9px
      .footer-copy
        font-size: 16px
  .section-questions
    .question-item
      grid-column: 1/13
      padding: 10px 45px 25px
      p
        font-size: 16px
    .accordion
      &.active
        padding-bottom: 25px

  .section-designed
    h2
      grid-column: 1/13
      text-align: left
    .section-block-1-a
      grid-column: 2/13

    .section-block-1-b
      display: none

    .section-block-2-a
      display: none

    .section-block-2-b
      grid-column: 1/12
      h3
        margin-top: 1.5em

    .section-block-3-a
      grid-column: 4/13

    .section-block-3-b
      display: none


/* Phones */
@media only screen and (max-width : 576px)
  .section-designed

    .section-block-1-a
      grid-column: 1/13
      text-align: left
      span
        white-space: normal

    .section-block-2-b
      grid-column: 1/13
      span
        white-space: normal

    .section-block-3-a
      grid-column: 1/13
      text-align: left

  .section-how
    padding-top: 25px
    padding-bottom: 40px
    .how-text
      grid-column: 1/13
      text-align: left
      .h2-aqua
        padding-left: 0
        text-align: left


/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px)
  /**/


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px)
  /**/


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px)
  /**/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px)
  /**/


/* Small Devices, Tablets */
@media only screen and (min-width : 768px)
  /**/


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px)
  /**/


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px)
  /**/

