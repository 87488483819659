*
	box-sizing: border-box
	&:before, &:after
		box-sizing: border-box

html
	height: 100%

body
	font-size: 16px
	min-width: 320px
	position: relative
	line-height: 1.65
	font-family: roboto-grid, sans-serif
	font-weight: 300
	overflow-x: hidden
	opacity: 1

.img-responsive
	display: block
	max-width: 100%
	height: auto

.text-center
	text-align: center
