@import "../libs/normalize-css/normalize.css";
@import "../libs/slick/slick/slick.css";
@import "../libs/slick/slick/slick-theme.css";
@import "~video.js/dist/video-js.css";
@font-face {
  font-family: "roboto-grid";
  src: url("../fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "roboto-grid";
  src: url("../fonts/Roboto/Roboto-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "roboto-grid";
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "roboto-grid";
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "roboto-grid";
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "quicksand-grid";
  src: url("../fonts/Quicksand/Quicksand-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "quicksand-grid";
  src: url("../fonts/Quicksand/Quicksand-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "quicksand-grid";
  src: url("../fonts/Quicksand/Quicksand-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "quicksand-grid";
  src: url("../fonts/Quicksand/Quicksand-Bold.ttf");
  font-weight: bold;
}
* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: roboto-grid, sans-serif;
  font-weight: 300;
  overflow-x: hidden;
  opacity: 1;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

html {
  background-color: #fff;
}

body {
  min-height: 100%;
  font-size: 1rem;
}

h1 {
  font-family: quicksand-grid, sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 35px;
  text-align: center;
  margin-top: 25px;
}

h2 {
  font-family: quicksand-grid, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 35px;
  margin-top: 25px;
}

h3 {
  font-family: quicksand-grid, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #7dc242;
}

.aqua {
  color: #35bbdc;
}

.turtle {
  color: #7dc242;
}

.h2-aqua {
  font-family: quicksand-grid, sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 25px;
  color: black;
}

.grid {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-column-gap: 40px;
}

.grid-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 35px;
}

.grid-16 {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column-gap: 40px;
}

.flex {
  display: flex;
}

.container {
  max-width: 1280px;
  margin: auto;
  padding: 0 15px;
}

.container-fluid {
  max-width: 1440px;
  margin: auto;
}

a {
  color: black;
  text-decoration: underline;
}

.read-link {
  font-family: quicksand-grid, sans-serif;
  font-weight: bold;
  color: #35bbdc;
  padding: 12px 38px;
  font-size: 19px;
  min-height: 54px;
  border-radius: 27px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  background: white;
  margin: 8px 0 16px -38px;
  display: inline-block;
  text-decoration: none;
}

.button-aqua {
  color: #ffffff;
  background-color: #35bbdc;
  font-family: quicksand-grid, sans-serif;
  font-weight: normal;
  text-decoration: none;
}
.button-aqua:hover {
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  background-color: #25b0d3;
}

.button-radius {
  padding: 16px 38px;
  height: 54px;
  border-radius: 27px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  font-weight: bold;
}

.green-text {
  color: #7dc242;
  font-weight: 500;
}

.aqua-text {
  color: #35bbdc;
  font-weight: 500;
}

.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-80 {
  margin-bottom: 80px;
}

.morouna-description {
  margin-bottom: 80px;
}

p {
  font-size: 16px;
  line-height: 1.75;
  font-weight: 300;
  font-family: roboto-grid, sans-serif;
}

html:not([data-scroll="0"]) body {
  margin-top: 75px;
}
html:not([data-scroll="0"]) .main-header {
  position: fixed;
  padding-top: 20px;
  padding-bottom: 15px;
  background: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
}
html:not([data-scroll="0"]) .main-header .main-logo {
  padding: 0;
}
html:not([data-scroll="0"]) .main-header .main-logo img {
  width: 160px;
}
html:not([data-scroll="0"]) .main-header .main-logo svg {
  width: 50px !important;
}
html:not([data-scroll="0"]) .main-header .nav-menu a.active::after, html:not([data-scroll="0"]) .main-header .nav-menu a.active:hover::after {
  height: 4px;
  border-radius: 2px;
}
html:not([data-scroll="0"]) .main-header .auth {
  height: 34px;
  border-radius: 17px;
  box-shadow: none;
}
html:not([data-scroll="0"]) .main-header .auth .button-login {
  padding: 9px 30px 9px 25px;
  margin-right: -40px;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
html:not([data-scroll="0"]) .main-header .auth .button-register {
  border-radius: 17px;
  padding: 9px 15px;
  white-space: nowrap;
  font-weight: 500;
  line-height: 1;
}
html:not([data-scroll="0"]) .main-header .auth .button-register::before {
  width: 35px;
  height: 22px;
  top: -20px;
  background-size: 35px 22px;
}

.main-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.15s;
  padding-top: 85px;
  padding-bottom: 45px;
  background: white;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08);
}
.main-header a {
  font-family: roboto-grid, sans-serif;
  font-size: 15px;
  font-weight: 300;
}
.main-header .container {
  align-items: center;
}
.main-header .grid {
  grid-row-gap: 40px;
}
.main-header .main-logo {
  padding: 10px 0;
  width: 277px;
  grid-column: 1/4;
}
.main-header .main-logo img {
  transition: all 0.15s;
  vertical-align: middle;
}
.main-header .main-logo svg {
  vertical-align: middle;
}
.main-header .main-logo .stlogo {
  fill: none;
  stroke: #eff4f7;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.main-header .main-logo .logo-loading-animated {
  stroke-dasharray: 10;
  stroke-dashoffset: 0;
  animation: dashes 1.5s linear infinite;
}
@keyframes dashes {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.main-header .nav-menu {
  grid-column: 5/11;
  display: flex;
  justify-content: space-around;
}
.main-header .nav-menu a {
  padding: 10px 18px;
  transition: all 0.3s;
  text-decoration: none;
}
.main-header .nav-menu a.active, .main-header .nav-menu a.active:hover {
  font-weight: bold;
  background-color: white;
  position: relative;
}
.main-header .nav-menu a.active::after, .main-header .nav-menu a.active:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  border-radius: 3px;
  background: #c9c6b8;
}
.main-header .nav-menu a:hover {
  color: black;
  background-color: #eff4f7;
  border-radius: 23px;
}
.main-header .nav-menu .dropdown {
  display: flex;
  position: relative;
}
.main-header .nav-menu .dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
}
.main-header .nav-menu .dropdown .dropdown-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 47px;
  left: 0;
  width: max-content;
  padding: 15px 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  z-index: 1;
  transition: all 0.3s;
}
.main-header .nav-menu .dropdown .dropdown-content a {
  display: block;
  padding: 6px 14px;
  text-align: left;
}
.main-header .nav-menu .dropdown .dropdown-content a:hover, .main-header .nav-menu .dropdown .dropdown-content a.dropdown-item-active {
  background-color: #35bbdc;
  border-radius: 20px;
  color: #fff;
}
.main-header .nav-menu .dropdown .dropdown-content a svg {
  margin-right: 10px;
  vertical-align: middle;
}
.main-header .nav-menu .dropdown .dropdown-content span {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 300;
}
.main-header .nav-menu #close-btn {
  display: none;
}
.main-header .auth {
  justify-self: end;
  grid-column: 11/15;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  padding-left: 0;
  height: 54px;
  border-radius: 27px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.15s;
}
.main-header .auth a {
  text-decoration: none;
}
.main-header .auth .button-login {
  position: relative;
  height: 100%;
  padding: 19px 50px 19px 30px;
  margin-right: -50px;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  white-space: nowrap;
  font-weight: 300;
  line-height: 1;
  transition: all 0.15s;
}
.main-header .auth .button-login:hover {
  background: #eff4f7;
}
.main-header .auth .button-register {
  position: relative;
  height: 100%;
  margin-left: 30px;
  border-radius: 27px;
  padding: 19px 30px;
  white-space: nowrap;
  font-weight: 500;
  line-height: 1;
  transition: all 0.15s;
}
.main-header .auth .button-register::before {
  content: "";
  background: url("../img/pic-mini-lamp.svg") no-repeat;
  transition: all 0.15s;
  display: block;
  position: absolute;
  width: 74px;
  height: 62px;
  top: -60px;
  left: 0;
}
.main-header #menu-button {
  display: none;
}
.main-header .header-title {
  grid-column: 1/6;
  font-size: 30px;
  font-family: quicksand-grid, sans-serif;
  font-weight: normal;
}
.main-header .search {
  grid-column: 7/15;
}
.main-header .search input {
  padding: 10px 20px;
  width: 100%;
  border-radius: 18px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #979797;
  background-color: #ffffff;
}
.main-header .search input::placeholder {
  color: #4a4a4a;
  opacity: 0.25;
  font-size: 14px;
  font-weight: normal;
}

.section-slider {
  padding-top: 65px;
  padding-bottom: 65px;
  overflow: hidden;
  position: relative;
}
.section-slider-left {
  grid-column: 1/6;
}
.section-slider-right {
  position: relative;
  grid-column: 7/13;
  min-width: 0;
  min-height: 0;
}
.section-slider-right .bg-img {
  position: absolute;
  width: 1280px;
  top: -520px;
  left: -95px;
  z-index: -1;
}

.section-designed {
  padding-top: 15px;
}
.section-designed h2 {
  grid-column: 1/7;
  text-align: right;
}
.section-designed h3 {
  margin-top: 1.5em;
}
.section-designed .section-block-1-a {
  text-align: right;
  grid-column: 1/7;
}
.section-designed .section-block-1-a span {
  white-space: nowrap;
}
.section-designed .section-block-1-a h3 {
  color: #35bbdc;
}
.section-designed .section-block-1-b {
  grid-column: 7/13;
}
.section-designed .section-block-1-b img {
  width: 120px;
}
.section-designed .section-block-2-a {
  text-align: right;
  grid-column: 1/7;
}
.section-designed .section-block-2-a img {
  width: 266px;
  margin-top: 45px;
  margin-right: -25px;
}
.section-designed .section-block-2-b {
  grid-column: 7/13;
}
.section-designed .section-block-2-b h3 {
  margin-top: 123px;
  color: #7dc242;
}
.section-designed .section-block-2-b span {
  white-space: nowrap;
}
.section-designed .section-block-3-a {
  text-align: right;
  grid-column: 1/7;
}
.section-designed .section-block-3-a span {
  white-space: nowrap;
}
.section-designed .section-block-3-a h3 {
  color: #35bbdc;
}
.section-designed .section-block-3-b {
  grid-column: 7/13;
}
.section-designed .section-block-3-b img {
  width: 120px;
  margin-top: 31px;
}

.section-tool {
  padding-top: 65px;
  padding-bottom: 65px;
  background-color: #fff;
}
.section-tool .grid {
  grid-template-rows: repeat(6, 1fr);
}
.section-tool .tool-title {
  grid-column: 1/7;
  grid-row: 1/3;
}
.section-tool .tool-left {
  grid-column: 1/7;
  grid-row: 2/6;
}
.section-tool .tool-left > p {
  font-size: 22px;
  font-weight: 300;
}
.section-tool .tool-left .flex {
  justify-content: space-between;
}
.section-tool .tool-left .flex p {
  width: 45%;
  font-family: roboto-grid, sans-serif;
  font-weight: 300;
}
.section-tool .tool-right {
  grid-column: 8/15;
  grid-row: 1/6;
  min-width: 0;
  min-height: 0;
}

.main-slider .item:focus {
  outline: none;
}

.section-how {
  padding-top: 60px;
  padding-bottom: 150px;
}
.section-how .how-text {
  grid-column: 1/8;
  grid-row: 1/3;
  text-align: right;
}
.section-how .how-text a {
  color: #35bbdc;
  text-decoration: underline;
}
.section-how .how-text .h2-aqua {
  padding-left: 120px;
}
.section-how .scheme {
  pointer-events: none;
  grid-column: 1/15;
  grid-row: 2/5;
  margin-top: 35px;
}
.section-how .scheme .mobile {
  display: none;
}

.section-features {
  padding-top: 10px;
  padding-bottom: 180px;
  background-color: #eff4f7;
  position: relative;
}
.section-features .bottom {
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(180deg, rgb(239, 244, 247) 0%, rgb(255, 255, 255) 100%);
}
.section-features p {
  margin-top: 0;
  font-weight: 300;
  font-size: 19px;
}
.section-features p b {
  font-weight: 500;
}
.section-features p small {
  font-size: 15px;
}
.section-features img {
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
}
.section-features .widgets {
  margin-bottom: -95px;
}
.section-features .widgets h2 {
  grid-column: 1/15;
  grid-row: 1/2;
  text-align: center;
  font-weight: bold;
}
.section-features .widgets .widget-1 {
  grid-column: 1/10;
  grid-row: 2/5;
}
.section-features .widgets .widget-1-1 {
  grid-column: 1/10;
  grid-row: 2/5;
}
.section-features .widgets .widget-1-2 {
  grid-column: 3/13;
  grid-row: 3/6;
}
.section-features .widgets .widget-text-1 {
  grid-column: 10/15;
  grid-row: 2/3;
}
.section-features .widgets .widget-text-2 {
  grid-column: 1/15;
  grid-row: 8/9;
  text-align: center;
}
.section-features .widgets .widget-2 {
  grid-column: 2/6;
  grid-row: 5/8;
}
.section-features .widgets .widget-3 {
  grid-column: 6/10;
  grid-row: 5/8;
}
.section-features .widgets .widget-4 {
  grid-column: 10/14;
  grid-row: 5/8;
}
.section-features .calendars {
  margin-bottom: 95px;
}
.section-features .calendars h2 {
  grid-column: 1/15;
  grid-row: 1/2;
  text-align: center;
  font-weight: bold;
}
.section-features .calendars .calendars-1 {
  grid-column: 8/15;
  grid-row: 2/5;
}
.section-features .calendars .calendars-2 {
  grid-column: 1/11;
  grid-row: 3/6;
}
.section-features .calendars .calendars-3 {
  grid-column: 5/15;
  grid-row: 4/7;
}
.section-features .calendars .calendars-text-1 {
  grid-column: 1/8;
  grid-row: 2/3;
}
.section-features .calendars .calendars-text-2 {
  grid-column: 1/6;
  grid-row: 6/7;
}
.section-features .payrolls {
  margin-bottom: 95px;
}
.section-features .payrolls h2 {
  grid-column: 1/15;
  grid-row: 1/2;
  text-align: center;
  font-weight: bold;
}
.section-features .payrolls .payrolls-1 {
  grid-column: 8/15;
  grid-row: 2/5;
}
.section-features .payrolls .payrolls-2 {
  grid-column: 1/11;
  grid-row: 3/6;
}
.section-features .payrolls .payrolls-text-1 {
  grid-column: 1/8;
  grid-row: 2/3;
}
.section-features .payrolls .payrolls-text-2 {
  grid-column: 1/6;
  grid-row: 8/9;
}
.section-features .payments {
  margin-bottom: 60px;
}
.section-features .payments h2 {
  grid-column: 1/15;
  grid-row: 1/2;
  text-align: center;
  font-weight: bold;
}
.section-features .payments .payment-text-1 {
  grid-column: 1/6;
  grid-row: 2/5;
}
.section-features .payments .payment-1 {
  grid-column: 6/15;
  grid-row: 2/6;
  z-index: 1;
}
.section-features .payments .payment-2 {
  grid-column: 1/10;
  grid-row: 5/8;
}
.section-features .payments .payment-text-2 {
  grid-column: 10/15;
  grid-row: 7/8;
}
.section-features .payments .payment-3 {
  grid-column: 1/8;
  grid-row: 7/10;
}
.section-features .payments .payment-text-3 {
  grid-column: 8/15;
  grid-row: 9/10;
}
.section-features .employees {
  margin-bottom: 80px;
}
.section-features .employees h2 {
  grid-column: 1/15;
  grid-row: 1/2;
  text-align: center;
  font-weight: bold;
}
.section-features .employees .employee-text-1 {
  grid-column: 1/6;
  grid-row: 2/3;
}
.section-features .employees .employee-1 {
  grid-column: 6/15;
  grid-row: 2/4;
}
.section-features .employees .employee-2 {
  grid-column: 1/8;
  grid-row: 3/5;
}
.section-features .employees .employee-text-2 {
  grid-column: 8/15;
}

.section-price {
  padding-top: 10px;
}
.section-price .item-price {
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 40px;
  margin-top: 40px;
}
.section-price .item-price .title {
  border-radius: 30px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  font-family: quicksand-grid, sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 60px;
  margin: -65px auto 40px;
}
.section-price .item-price .price {
  margin: 0;
  font-size: 30px;
  font-weight: bold;
}
.section-price .item-price .button-price {
  display: block;
  font-size: 18px;
  line-height: 20px;
  padding: 10px;
}
.section-price .item-price-1 {
  grid-column: 2/6;
}
.section-price .item-price-2 {
  grid-column: 6/10;
}
.section-price .item-price-3 {
  grid-column: 10/14;
}
.section-price .price-text {
  margin-top: 70px;
  margin-bottom: 60px;
}
.section-price .button-free {
  display: inline-block;
  padding: 6px 30px;
  font-size: 18px;
}

.section-form {
  position: relative;
  margin-top: 70px;
  background-color: #fff;
}
.section-form.home {
  margin-top: -70px;
}
.section-form .grid {
  grid-template-rows: repeat(3, 1fr);
}
.section-form .form {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  grid-column: 4/12;
  grid-row: 1/4;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
}
.section-form .form label {
  display: inline-block;
  font-size: 15px;
}
.section-form .label-email {
  font-weight: normal;
}
.section-form .label-message span {
  font-style: italic;
}
.section-form .form-control {
  width: 100%;
  padding: 2px 5px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #979797;
  background-color: #ffffff;
  line-height: 2em;
}
.section-form .form-group {
  margin-bottom: 24px;
}
.section-form .form-header {
  width: 100%;
  height: 21px;
  background: url("../img/envelope-stripe.png") repeat-x;
  background-size: contain;
}
.section-form .form-body {
  padding: 5px 80px 30px;
}
.section-form .form-body h2 {
  margin-bottom: 20px;
}
.section-form .form-body .buttons {
  display: flex;
  justify-content: flex-end;
}
.section-form .form-body .button-green {
  padding: 10px 35px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #7dc242;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: none;
}
.section-form .form-body .button-green:hover {
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  background-color: #71b139;
}
.section-form .pic-lamp {
  grid-column: 1/4;
  grid-row: 3/4;
  align-self: end;
}
.section-form .pic-comp {
  grid-column: 12/15;
  grid-row: 3/4;
  justify-self: end;
  align-self: end;
}

.main-footer {
  background-color: #eff4f7;
  padding-top: 130px;
  padding-bottom: 70px;
}
.main-footer .footer-left {
  grid-column: 1/5;
}
.main-footer .footer-right {
  text-align: right;
  color: #9ba1a5;
  grid-column: 12/15;
}
.main-footer .footer-right h2 {
  margin-top: 0;
  line-height: 1;
}
.main-footer .footer-right .contacts .phone, .main-footer .footer-right .contacts .email {
  display: flex;
  justify-content: space-between;
}
.main-footer .footer-right .contacts .phone p, .main-footer .footer-right .contacts .email p {
  margin: 0;
  font-size: 15px;
}
.main-footer .footer-logo {
  max-width: 200px;
  margin-bottom: 35px;
}
.main-footer .footer-copy {
  margin-bottom: 0;
  color: #9ba1a5;
}
.main-footer .terms a {
  margin-right: 18px;
}
.main-footer a {
  font-size: 15px;
  color: #9ba1a5;
  text-decoration: underline;
}

.section-questions {
  padding: 50px 0;
}
.section-questions .question-item {
  grid-column: 2/14;
  padding: 10px 95px 40px;
  margin-bottom: 30px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}
.section-questions .question-item p {
  font-size: 16px;
}
.section-questions .question-important h2 {
  font-family: quicksand-grid, sans-serif;
  font-weight: 500;
}
.section-questions .accordion {
  padding-bottom: 0;
  cursor: pointer;
  height: inherit;
}
.section-questions .accordion:hover {
  background: #eff4f7;
}
.section-questions .accordion .panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
}
.section-questions .accordion.active {
  padding-bottom: 40px;
}
.section-questions .accordion.active .panel {
  overflow: inherit;
  transition: max-height 0.2s ease-out;
}

/* slick slider */
.slick-list {
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
}

.morouna-slick-dots {
  position: absolute;
  bottom: -35px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.morouna-slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.morouna-slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  cursor: pointer;
  border: 1px solid #979797;
  border-radius: 50%;
  background: transparent;
  padding: 0;
}

.morouna-slick-dots li button:hover,
.morouna-slick-dots li button:focus {
  outline: none;
}

.morouna-slick-dots li button:hover,
.morouna-slick-dots li button:focus {
  background-color: #9ba1a5;
}

.morouna-slick-dots li.slick-active button {
  background-color: #9b9b9b;
}

.flash {
  padding: 15px;
  text-align: center;
  font-size: 22px;
}
.flash-success {
  background-color: #7dc242;
}
.flash-error {
  background-color: #da6e2b;
}

.come-in {
  transform: scale(0.75, 0.75);
  animation: come-in 0.8s ease forwards;
  opacity: 0;
}

.come-in:nth-child(odd),
*:nth-child(odd) .come-in {
  animation-delay: 0.2s;
}

.already-visible {
  transform: scale(1, 1);
  opacity: 1;
  animation: none;
}

@keyframes come-in {
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}
.video-wrapper {
  margin: 1rem 0;
}
.video-wrapper.grid div:first-child {
  grid-column: 1/4;
}
.video-wrapper.grid div:nth-child(2) {
  grid-column: 4/12;
}

.video-js {
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
}
.video-js .vjs-big-play-button {
  left: 50%;
  top: 50%;
  margin-top: -1.5em;
  margin-left: -1.5em;
  line-height: 3em;
  height: 3em;
  border-radius: 1.5em;
  background-color: rgba(0, 0, 0, 0.08);
}
.video-js .vjs-text-track-cue {
  font-family: quicksand-grid, sans-serif;
}
.video-js .vjs-text-track-cue div {
  font-family: quicksand-grid, sans-serif;
  color: black !important;
  background: white !important;
}

/*==========  Desktop First  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  /**/
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  h2 {
    font-size: 24px;
  }
  .grid {
    grid-column-gap: 20px;
  }
  .main-header .grid {
    grid-row-gap: 25px;
  }
  .main-header .main-logo {
    grid-column: 2/14;
    justify-self: center;
  }
  .main-header .nav-menu {
    grid-column: 1/9;
  }
  .main-header .auth {
    grid-column: 10/15;
    justify-content: space-around;
  }
  .main-header .auth .button-register:before {
    content: none;
  }
  .section-slider-left {
    grid-column: 1/13;
    margin-bottom: 50px;
    text-align: center;
  }
  .section-slider-right {
    grid-column: 1/13;
  }
  .section-tool {
    padding-top: 25px;
    padding-bottom: 40px;
  }
  .section-tool h2 {
    text-align: center;
  }
  .section-tool .grid {
    grid-template-rows: initial;
  }
  .section-tool .tool-title {
    grid-column: 1/15;
    grid-row: initial;
    justify-content: center;
  }
  .section-tool .tool-left {
    grid-column: 1/15;
    grid-row: initial;
  }
  .section-tool .tool-right {
    grid-column: 1/15;
    grid-row: initial;
  }
  .section-how {
    padding-top: 25px;
    padding-bottom: 40px;
  }
  .section-how .how-text {
    grid-column: 1/9;
  }
  .section-features {
    padding-bottom: 60px;
  }
  .section-features .widgets {
    margin-bottom: 70px;
  }
  .section-price {
    padding-top: 25px;
  }
  .section-price .item-price {
    padding: 20px;
  }
  .section-form .form {
    top: -100px;
    min-width: 550px;
  }
  .section-form .form-body {
    padding: 5px 40px 30px;
  }
  .main-footer {
    padding-bottom: 40px;
  }
  .main-footer .footer-left {
    grid-column: 1/7;
  }
  .main-footer .footer-right {
    grid-column: 11/15;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  body {
    font-size: 14px;
    transition: margin-left 0.5s;
  }
  .mask {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    background-color: rgb(0, 0, 0);
    opacity: 0.5;
    display: none;
  }
  .mask::before {
    content: "";
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.3);
    filter: blur(10px);
  }
  h2 {
    text-align: center;
    margin-bottom: 15px;
  }
  .grid {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 10px;
  }
  .grid-12 {
    grid-column-gap: 10px;
  }
  .main-header {
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .main-header .grid {
    grid-row-gap: 10px;
  }
  .main-header .main-logo {
    grid-column: 2/12;
  }
  .main-header a {
    font-size: 16px;
  }
  .main-header #menu-button {
    display: block;
    position: relative;
    left: -18px;
    font-size: 40px;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
    color: #da6e2b;
  }
  .main-header .nav-menu {
    display: block;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  .main-header .nav-menu a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
    transition: 0.3s;
  }
  .main-header .nav-menu a:hover {
    color: #9b9b9b;
  }
  .main-header .nav-menu #close-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .main-header .nav-menu #close-btn:focus {
    outline: none;
  }
  .main-header .nav-menu .dropdown {
    display: block;
    position: relative;
  }
  .main-header .nav-menu .dropdown .dropdown-content {
    visibility: visible;
    opacity: 1;
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    border-radius: 0;
  }
  .main-header .nav-menu .dropdown .dropdown-content a {
    font-size: 15px;
  }
  .main-header .auth {
    grid-column: 1/13;
    justify-self: center;
  }
  .section-tool {
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .section-tool .tool-title {
    grid-column: 1/13;
  }
  .section-tool .tool-left {
    grid-column: 2/12;
  }
  .section-tool .tool-left > p {
    font-size: 17px;
  }
  .section-tool .tool-left .flex {
    flex-wrap: wrap;
  }
  .section-tool .tool-left .flex p {
    width: 100%;
  }
  .section-tool .tool-right {
    grid-column: 1/13;
  }
  .section-how {
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .section-how .how-text {
    grid-column: 2/12;
    grid-row: initial;
    margin-bottom: 10px;
  }
  .section-how .how-text p {
    font-size: 16px;
  }
  .section-how .scheme {
    grid-row: initial;
  }
  .section-how .scheme .desktop {
    display: none;
  }
  .section-how .scheme .mobile {
    display: block;
    max-height: 850px;
  }
  .section-features {
    padding-bottom: 40px;
  }
  .section-features .grid {
    grid-row-gap: 20px;
  }
  .section-features .widgets {
    margin-bottom: 45px;
  }
  .section-features .widgets .widget-1 {
    margin: 0 -15px;
    grid-column: 1/13;
    grid-row: initial;
  }
  .section-features .widgets .widget-text {
    grid-column: 2/12;
    grid-row: initial;
  }
  .section-features .widgets .widget-2 {
    grid-column: 1/5;
  }
  .section-features .widgets .widget-3 {
    grid-column: 5/9;
  }
  .section-features .widgets .widget-4 {
    grid-column: 9/13;
  }
  .section-features .payments {
    margin-bottom: 45px;
  }
  .section-features .payments .payment-text-1 {
    grid-column: 2/12;
    grid-row: initial;
  }
  .section-features .payments .payment-1 {
    margin: 0 -15px;
    grid-column: 1/13;
    grid-row: initial;
  }
  .section-features .payments .payment-text-2 {
    grid-column: 2/12;
    grid-row: initial;
  }
  .section-features .payments .payment-2 {
    margin: 0 -15px;
    grid-column: 1/13;
    grid-row: initial;
  }
  .section-features .employees .employee-text-1 {
    order: 1;
    grid-column: 2/12;
    grid-row: initial;
  }
  .section-features .employees .employee-1 {
    margin: 0 -15px;
    order: 4;
    grid-column: 1/13;
    grid-row: initial;
  }
  .section-features .employees .employee-text-2 {
    order: 3;
    grid-column: 2/12;
    grid-row: initial;
  }
  .section-features .employees .employee-2 {
    order: 2;
    grid-column: 1/13;
    grid-row: initial;
  }
  .section-price {
    padding-top: 5px;
  }
  .section-price .item-price-1 {
    grid-column: 2/12;
  }
  .section-price .item-price-2, .section-price .item-price-3 {
    display: none;
  }
  .section-price .price-text {
    display: none;
  }
  .section-form {
    margin-top: 0;
  }
  .section-form .grid {
    grid-template-rows: initial;
  }
  .section-form.home {
    margin-top: -35px;
  }
  .section-form .form {
    grid-column: 1/13;
    grid-row: initial;
    position: inherit;
    top: 0;
    left: 0;
    margin-bottom: 50px;
    min-width: inherit;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  .section-form .pic-lamp {
    grid-column: 1/5;
    grid-row: initial;
  }
  .section-form .pic-comp {
    grid-column: 8/13;
    grid-row: initial;
  }
  .main-footer {
    padding-top: 30px;
  }
  .main-footer h2 {
    color: black;
  }
  .main-footer .footer-right {
    order: 1;
    grid-column: 2/12;
    margin-bottom: 45px;
  }
  .main-footer .footer-left {
    order: 2;
    grid-column: 2/12;
    text-align: center;
  }
  .main-footer .footer-left .footer-logo {
    display: none;
  }
  .main-footer .footer-left .terms a {
    display: inline-block;
    font-size: 15px;
    margin: 0 9px;
  }
  .main-footer .footer-left .footer-copy {
    font-size: 16px;
  }
  .section-questions .question-item {
    grid-column: 1/13;
    padding: 10px 45px 25px;
  }
  .section-questions .question-item p {
    font-size: 16px;
  }
  .section-questions .accordion.active {
    padding-bottom: 25px;
  }
  .section-designed h2 {
    grid-column: 1/13;
    text-align: left;
  }
  .section-designed .section-block-1-a {
    grid-column: 2/13;
  }
  .section-designed .section-block-1-b {
    display: none;
  }
  .section-designed .section-block-2-a {
    display: none;
  }
  .section-designed .section-block-2-b {
    grid-column: 1/12;
  }
  .section-designed .section-block-2-b h3 {
    margin-top: 1.5em;
  }
  .section-designed .section-block-3-a {
    grid-column: 4/13;
  }
  .section-designed .section-block-3-b {
    display: none;
  }
}
/* Phones */
@media only screen and (max-width: 576px) {
  .section-designed .section-block-1-a {
    grid-column: 1/13;
    text-align: left;
  }
  .section-designed .section-block-1-a span {
    white-space: normal;
  }
  .section-designed .section-block-2-b {
    grid-column: 1/13;
  }
  .section-designed .section-block-2-b span {
    white-space: normal;
  }
  .section-designed .section-block-3-a {
    grid-column: 1/13;
    text-align: left;
  }
  .section-how {
    padding-top: 25px;
    padding-bottom: 40px;
  }
  .section-how .how-text {
    grid-column: 1/13;
    text-align: left;
  }
  .section-how .how-text .h2-aqua {
    padding-left: 0;
    text-align: left;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  /**/
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  /**/
}
/*==========  Mobile First  ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  /**/
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  /**/
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  /**/
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  /**/
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  /**/
}