html
  background-color: #fff
body
  min-height: 100%
  font-size: 1rem
h1
  font-family: quicksand-grid, sans-serif
  font-size: 1.6rem
  font-weight: bold
  margin-bottom: 35px
  text-align: center
  margin-top: 25px
h2
  font-family: quicksand-grid, sans-serif
  font-size: 1.6rem
  font-weight: normal
  margin-bottom: 35px
  margin-top: 25px
h3
  font-family: quicksand-grid, sans-serif
  font-size: 1.4rem
  font-weight: 500
  line-height: 1.5
  margin-bottom: 15px
  color: $turtle-green

.aqua
  color: $aqua-marine
.turtle
  color: $turtle-green

.h2-aqua
  font-family: quicksand-grid, sans-serif
  font-size: 32px
  font-weight: 300
  line-height: normal
  margin-bottom: 25px
  color: black
.grid
  display: grid
  grid-template-columns: repeat(14, 1fr)
  grid-column-gap: 40px
.grid-12
  display: grid
  grid-template-columns: repeat(12, 1fr)
  grid-column-gap: 35px
.grid-16
  display: grid
  grid-template-columns: repeat(16, 1fr)
  grid-column-gap: 40px
.flex
  display: flex
.container
  max-width: 1280px
  margin: auto
  padding: 0 15px
.container-fluid
  max-width: 1440px
  margin: auto
a
  color: black
  text-decoration: underline
.read-link
  font-family: quicksand-grid, sans-serif
  font-weight: bold
  color: $aqua-marine
  padding: 12px 38px
  font-size: 19px
  min-height: 54px
  border-radius: 27px
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
  background: white
  margin: 8px 0 16px -38px
  display: inline-block
  text-decoration: none

.button-aqua
  color: #ffffff
  background-color: $aqua-marine
  font-family: quicksand-grid, sans-serif
  font-weight: normal
  text-decoration: none
  &:hover
    -webkit-transition: background-color .2s ease
    -moz-transition: background-color .2s ease
    -ms-transition: background-color .2s ease
    -o-transition: background-color .2s ease
    transition: background-color .2s ease
    background-color: darken($aqua-marine, 5%)
.button-radius
  padding: 16px 38px
  height: 54px
  border-radius: 27px
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
  font-weight: bold
.green-text
  color: $turtle-green
  font-weight: 500
.aqua-text
  color: $aqua-marine
  font-weight: 500
.m-0
  margin: 0
.mb-10
  margin-bottom: 10px
.mb-80
  margin-bottom: 80px

.morouna-description
  margin-bottom: 80px


p
  font-size: 16px
  line-height: 1.75
  font-weight: 300
  font-family: roboto-grid, sans-serif


html:not([data-scroll='0'])
  body
    margin-top: 75px
  .main-header
    position: fixed
    padding-top: 20px
    padding-bottom: 15px
    background: white
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08)
    .main-logo
      padding: 0
      img
        width: 160px
      svg
        width: 50px !important

    .nav-menu
      a
        &.active, &.active:hover
          &::after
            height: 4px
            border-radius: 2px
    .auth
      height: 34px
      border-radius: 17px
      box-shadow: none
      .button-login
        padding: 9px 30px 9px 25px
        margin-right: -40px
        border-top-left-radius: 17px
        border-bottom-left-radius: 17px
      .button-register
        border-radius: 17px
        padding: 9px 15px
        white-space: nowrap
        font-weight: 500
        line-height: 1
        &::before
          width: 35px
          height: 22px
          top: -20px
          background-size: 35px 22px


// header
.main-header
  top: 0
  left: 0
  right: 0
  z-index: 1000
  transition: all 0.15s
  padding-top: 85px
  padding-bottom: 45px
  background: white
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.08)

  a
    font-family: roboto-grid, sans-serif
    font-size: 15px
    font-weight: 300
  .container
    align-items: center
  .grid
    grid-row-gap: 40px
  .main-logo
    padding: 10px 0
    width: 277px
    grid-column: 1/4
    img
      transition: all 0.15s
      vertical-align: middle
    svg
      vertical-align: middle
    .stlogo
      fill: none
      stroke: #eff4f7
      stroke-width: 5px
      stroke-linecap: round
      stroke-miterlimit: 10
    .logo-loading-animated
      stroke-dasharray: 10
      stroke-dashoffset: 0
      animation: dashes 1.5s linear infinite
    @keyframes dashes
      from
        stroke-dashoffset: 100
      to
        stroke-dashoffset: 0

  .nav-menu
    grid-column: 5/11
    display: flex
    justify-content: space-around
    a
      padding: 10px 18px
      transition: all 0.3s
      text-decoration: none
      &.active, &.active:hover
        font-weight: bold
        background-color: white
        position: relative
        &::after
          content: ""
          position: absolute
          bottom: 0
          left: 0
          right: 0
          height: 6px
          border-radius: 3px
          background: $beige
      &:hover
        color: black
        background-color: $ice-blue
        border-radius: 23px

    .dropdown
      display: flex
      position: relative
      &:hover
        .dropdown-content
          visibility: visible
          opacity: 1
      .dropdown-content
        visibility: hidden
        opacity: 0
        position: absolute
        top: 47px
        left: 0
        width: max-content
        padding: 15px 15px 20px
        background-color: #fff
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09)
        border-radius: 20px
        z-index: 1
        transition: all 0.3s
        a
          display: block
          padding: 6px 14px
          text-align: left
          &:hover, &.dropdown-item-active
            background-color: $aqua-marine
            border-radius: 20px
            color: #fff
          svg
            margin-right: 10px
            vertical-align: middle

        span
          display: inline-block
          margin-left: 10px
          margin-bottom: 15px
          font-size: 15px
          font-weight: 300

    #close-btn
      display: none
  .auth
    a
      text-decoration: none
    justify-self: end
    grid-column: 11/15
    display: flex
    justify-content: flex-end
    align-items: center
    width: fit-content
    padding-left: 0
    height: 54px
    border-radius: 27px
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
    transition: all 0.15s
    .button-login
      position: relative
      height: 100%
      padding: 19px 50px 19px 30px
      margin-right: -50px
      border-top-left-radius: 27px
      border-bottom-left-radius: 27px
      white-space: nowrap
      font-weight: 300
      line-height: 1
      transition: all 0.15s
      &:hover
        background: $ice-blue
    .button-register
      position: relative
      height: 100%
      margin-left: 30px
      border-radius: 27px
      padding: 19px 30px
      white-space: nowrap
      font-weight: 500
      line-height: 1
      transition: all 0.15s
      &::before
        content: ''
        background: url("../img/pic-mini-lamp.svg") no-repeat
        transition: all 0.15s
        display: block
        position: absolute
        width: 74px
        height: 62px
        top: -60px
        left: 0
  #menu-button
    display: none
  .header-title
    grid-column: 1/6
    font-size: 30px
    font-family: quicksand-grid, sans-serif
    font-weight: normal
  .search
    grid-column: 7/15
    input
      padding: 10px 20px
      width: 100%
      border-radius: 18px
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)
      border: solid 1px #979797
      background-color: #ffffff
      &::placeholder
        color: #4a4a4a
        opacity: 0.25
        font-size: 14px
        font-weight: normal

// section default
.section-slider
  padding-top: 65px
  padding-bottom: 65px
  overflow: hidden
  position: relative
  &-left
    grid-column: 1/6
  &-right
    position: relative
    grid-column: 7/13
    min-width: 0
    min-height: 0
    .bg-img
      position: absolute
      width: 1280px
      top: -520px
      left: -95px
      z-index: -1

.section-designed
  padding-top: 15px

  h2
    grid-column: 1/7
    text-align: right

  h3
    margin-top: 1.5em

  .section-block-1-a
    text-align: right
    grid-column: 1/7
    span
      white-space: nowrap
    h3
      color: $aqua-marine
  .section-block-1-b
    grid-column: 7/13
    img
      width: 120px


  .section-block-2-a
    text-align: right
    grid-column: 1/7
    img
      width: 266px
      margin-top: 45px
      margin-right: -25px

  .section-block-2-b
    grid-column: 7/13
    h3
      margin-top: 123px
      color: $turtle-green
    span
      white-space: nowrap

  .section-block-3-a
    text-align: right
    grid-column: 1/7
    span
      white-space: nowrap
    h3
      color: $aqua-marine
  .section-block-3-b
    grid-column: 7/13
    img
      width: 120px
      margin-top: 31px

// section tool
.section-tool
  padding-top: 65px
  padding-bottom: 65px
  background-color: #fff
  .grid
    grid-template-rows: repeat(6, 1fr)
  .tool-title
    grid-column: 1/7
    grid-row: 1/3
  .tool-left
    grid-column: 1/7
    grid-row: 2/6
    &>p
      font-size: 22px
      font-weight: 300
    .flex
      justify-content: space-between
      p
        width: 45%
        font-family: roboto-grid, sans-serif
        font-weight: 300
  .tool-right
    grid-column: 8/15
    grid-row: 1/6
    min-width: 0
    min-height: 0
.main-slider
  .item
    &:focus
      outline: none
// section how
.section-how
  padding-top: 60px
  padding-bottom: 150px
  .how-text
    grid-column: 1/8
    grid-row: 1/3
    text-align: right
    a
      color: $aqua-marine
      text-decoration: underline
    .h2-aqua
      padding-left: 120px
  .scheme
    pointer-events: none
    grid-column: 1/15
    grid-row: 2/5
    margin-top: 35px
    .mobile
      display: none

// section features
.section-features
  padding-top: 10px
  padding-bottom: 180px
  background-color: $ice-blue
  position: relative
  .bottom
    position: absolute
    bottom: -60px
    left: 0
    right: 0
    height: 120px
    background: linear-gradient(180deg, rgba(239,244,247,1) 0%, rgba(255,255,255,1) 100%)
  p
    margin-top: 0
    font-weight: 300
    font-size: 19px
    b
      font-weight: 500
    small
      font-size: 15px
  img
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
  .widgets
    h2
      grid-column: 1/15
      grid-row: 1/2
      text-align: center
      font-weight: bold
    margin-bottom: -95px
    .widget-1
      grid-column: 1/10
      grid-row: 2/5
    .widget-1-1
      grid-column: 1/10
      grid-row: 2/5
    .widget-1-2
      grid-column: 3/13
      grid-row: 3/6
    .widget-text-1
      grid-column: 10/15
      grid-row: 2/3
    .widget-text-2
      grid-column: 1/15
      grid-row: 8/9
      text-align: center
    .widget-2
      grid-column: 2/6
      grid-row: 5/8
    .widget-3
      grid-column: 6/10
      grid-row: 5/8
    .widget-4
      grid-column: 10/14
      grid-row: 5/8
  .calendars
    margin-bottom: 95px
    h2
      grid-column: 1/15
      grid-row: 1/2
      text-align: center
      font-weight: bold
    .calendars-1
      grid-column: 8/15
      grid-row: 2/5
    .calendars-2
      grid-column: 1/11
      grid-row: 3/6
    .calendars-3
      grid-column: 5/15
      grid-row: 4/7
    .calendars-text-1
      grid-column: 1/8
      grid-row: 2/3
    .calendars-text-2
      grid-column: 1/6
      grid-row: 6/7
  .payrolls
    margin-bottom: 95px
    h2
      grid-column: 1/15
      grid-row: 1/2
      text-align: center
      font-weight: bold
    .payrolls-1
      grid-column: 8/15
      grid-row: 2/5
    .payrolls-2
      grid-column: 1/11
      grid-row: 3/6
    .payrolls-text-1
      grid-column: 1/8
      grid-row: 2/3
    .payrolls-text-2
      grid-column: 1/6
      grid-row: 8/9
  .payments
    h2
      grid-column: 1/15
      grid-row: 1/2
      text-align: center
      font-weight: bold
    margin-bottom: 60px
    .payment-text-1
      grid-column: 1/6
      grid-row: 2/5
    .payment-1
      grid-column: 6/15
      grid-row: 2/6
      z-index: 1
    .payment-2
      grid-column: 1/10
      grid-row: 5/8
    .payment-text-2
      grid-column: 10/15
      grid-row: 7/8
    .payment-3
      grid-column: 1/8
      grid-row: 7/10
    .payment-text-3
      grid-column: 8/15
      grid-row: 9/10
  .employees
    margin-bottom: 80px
    h2
      grid-column: 1/15
      grid-row: 1/2
      text-align: center
      font-weight: bold
    .employee-text-1
      grid-column: 1/6
      grid-row: 2/3
    .employee-1
      grid-column: 6/15
      grid-row: 2/4
    .employee-2
      grid-column: 1/8
      grid-row: 3/5
    .employee-text-2
      grid-column: 8/15

// section price
.section-price
  padding-top: 10px
  .item-price
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
    text-align: center
    padding: 40px
    margin-top: 40px
    .title
      border-radius: 30px
      box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
      background-color: #ffffff
      font-family: quicksand-grid, sans-serif
      font-weight: normal
      font-size: 30px
      line-height: 60px
      margin: -65px auto 40px
    .price
      margin: 0
      font-size: 30px
      font-weight: bold
    .button-price
      display: block
      font-size: 18px
      line-height: 20px
      padding: 10px
  .item-price-1
    grid-column: 2/6
  .item-price-2
    grid-column: 6/10
  .item-price-3
    grid-column: 10/14
  .price-text
    margin-top: 70px
    margin-bottom: 60px
  .button-free
    display: inline-block
    padding: 6px 30px
    font-size: 18px

// section form
.section-form
  position: relative
  margin-top: 70px
  background-color: #fff
  &.home
    margin-top: -70px
  .grid
    grid-template-rows: repeat(3, 1fr)
  .form
    position: absolute
    left: 50%
    transform: translateX(-50%)
    top: -10px
    grid-column: 4/12
    grid-row: 1/4
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
    background-color: #fff
    label
      display: inline-block
      font-size: 15px
  .label-email
    font-weight: normal
  .label-message
    span
      font-style: italic
  .form-control
    width: 100%
    padding: 2px 5px
    border-radius: 3px
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)
    border: solid 1px #979797
    background-color: #ffffff
    line-height: 2em
  .form-group
    margin-bottom: 24px
  .form-header
    width: 100%
    height: 21px
    background: url("../img/envelope-stripe.png") repeat-x
    background-size: contain
  .form-body
    padding: 5px 80px 30px
    h2
      margin-bottom: 20px
    .buttons
      display: flex
      justify-content: flex-end
    .button-green
      padding: 10px 35px
      font-size: 14px
      font-weight: bold
      color: #fff
      background-color: $turtle-green
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1)
      border: none
      &:hover
        cursor: pointer
        -webkit-transition: background-color .2s ease
        -moz-transition: background-color .2s ease
        -ms-transition: background-color .2s ease
        -o-transition: background-color .2s ease
        transition: background-color .2s ease
        background-color: darken($turtle-green, 5%)
  .pic-lamp
    grid-column: 1/4
    grid-row: 3/4
    align-self: end
  .pic-comp
    grid-column: 12/15
    grid-row: 3/4
    justify-self: end
    align-self: end
// footer
.main-footer
  background-color: $ice-blue
  padding-top: 130px
  padding-bottom: 70px
  .footer-left
    grid-column: 1/5
  .footer-right
    text-align: right
    color: #9ba1a5
    grid-column: 12/15
    h2
      margin-top: 0
      line-height: 1
    .contacts
      .phone, .email
        display: flex
        justify-content: space-between
        p
          margin: 0
          font-size: 15px
  .footer-logo
    max-width: 200px
    margin-bottom: 35px
  .footer-copy
    margin-bottom: 0
    color: #9ba1a5
  .terms
    a
      margin-right: 18px
  a
    font-size: 15px
    color: #9ba1a5
    text-decoration: underline

// section questions
.section-questions
  padding: 50px 0
  .question-item
    grid-column: 2/14
    padding: 10px 95px 40px
    margin-bottom: 30px
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08)
    background-color: #ffffff
    p
      font-size: 16px
  .question-important
    h2
      font-family: quicksand-grid, sans-serif
      font-weight: 500
  .accordion
    padding-bottom: 0
    cursor: pointer
    height: inherit
    &:hover
      background: $ice-blue
    .panel
      max-height: 0
      overflow: hidden
      transition: max-height 0.2s ease-in
    &.active
      padding-bottom: 40px
      .panel
        overflow: inherit
        transition: max-height 0.2s ease-out



//.active, .accordion:hover
//  background-color: #ccc

//.panel
//  max-height: 0
//  overflow: hidden
//  transition: max-height 0.2s ease-out

/* slick slider */
.slick-list
  box-shadow: 0 10px 35px 0 rgba(0,0,0,.08)
.morouna-slick-dots
  position: absolute
  bottom: -35px
  display: block
  width: 100%
  padding: 0
  margin: 0
  list-style: none
  text-align: center

.morouna-slick-dots li
  position: relative
  display: inline-block
  margin: 0 5px
  padding: 0
  cursor: pointer

.morouna-slick-dots li button
  font-size: 0
  line-height: 0
  display: block
  width: 8px
  height: 8px
  cursor: pointer
  border: 1px solid #979797
  border-radius: 50%
  background: transparent
  padding: 0

.morouna-slick-dots li button:hover,
.morouna-slick-dots li button:focus
  outline: none

.morouna-slick-dots li button:hover,
.morouna-slick-dots li button:focus
  background-color: #9ba1a5

.morouna-slick-dots li.slick-active button
  background-color: #9b9b9b

// flash message
.flash
  padding: 15px
  text-align: center
  font-size: 22px
  &-success
    background-color: $turtle-green
  &-error
    background-color: $terra-cotta

.come-in
  transform: scale(0.75, 0.75)
  animation: come-in 0.8s ease forwards
  opacity: 0

.come-in:nth-child(odd),
*:nth-child(odd) .come-in
  animation-delay: 0.2s /* So they look staggered */

.already-visible
  transform: scale(1, 1)
  opacity: 1
  animation: none

@keyframes come-in
  to
    transform: scale(1, 1)
    opacity: 1

.video-wrapper
  margin: 1rem 0
  &.grid
    div:first-child
      grid-column: 1/4
    div:nth-child(2)
      grid-column: 4/12

.video-js
  background-color: white
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08)
  .vjs-big-play-button
    left: 50%
    top: 50%
    margin-top: -1.5em
    margin-left: -1.5em
    line-height: 3em
    height: 3em
    border-radius: 1.5em
    background-color: rgba(0,0,0,0.08)
  .vjs-text-track-cue
    font-family: quicksand-grid, sans-serif
    div
      font-family: quicksand-grid, sans-serif
      color: black !important
      background: white !important